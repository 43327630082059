.rowSection {
  .modulesRow {
    width: 100%;
    &.modulesRowTitle {
      .modulesCol {
        text-align: center;
        h2 {
          margin-bottom: 0;
        }
        h3 {
          padding-bottom: $titleSectionMargin - 0.5em;
        }
      }
    }
    &.modulesRowSlider {
      .modulesCol {
        .modulesGif {
          width: 100%;
          height: 20vw;
          object-fit: cover;

          @media (max-width: 991px) {
            height: 60vw;
          }
        }
        .cardStyle {
          // margin: 0 3vw;
          .cardBody {
            .cardText {
              padding-left: 0;
              padding-right: 0;
            }
          }
          @media (max-width: 991px) {
            margin-bottom: 15vw;
            &:nth-last-child(1) {
              margin-bottom: 5vw;
            }
          }
        }
      }
      .cardStyle {
        border: none;

        position: relative;

        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
        }
        &::before {
          background-color: theme-color('neonSecondaryBlurr');
          height: 2px;
          filter: blur(2px);
          z-index: 1;
        }
        &::after {
          background-color: theme-color('neonSecondary');
          height: 1px;
          bottom: 0.5px;
          z-index: 2;
        }
        .cardHeader {
          padding: 0;
          img {
            width: 100%;
          }
        }
        .cardBody {
          padding: 0;
          p {
            padding: 2em;
          }
        }
        // .cardText {
        //   padding-left: 0 !important;
        //   padding-right: 0 !important;
        // }
        @media (max-width: 991px) {
          .cardText {
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 1em !important;
            padding-bottom: 1em !important;
            margin-bottom: 0;
          }
        }
      }
    }
    &.modulesRowButton {
      padding-top: 3vw;
      .modulesCol {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .modulesCol {
    }
  }
}
