@import "../../fonts/Micro/style.scss";
@import "../../fonts/OpenSans/style.scss";

@function get-vw($target) {
   $vw-context: (1300 * 0.01) * 1px;
   @return ($target / $vw-context) * 1vw;
}

* {
   color: theme-color("textColor");
}
a {
   text-decoration: none;
   &:hover {
      text-decoration: none;
   }
}
h1 {
   font-family: "Open Sans";
   font-style: normal;
   font-weight: bold;
   font-size: 220px;
   line-height: 90px;

   color: theme-color("bgColor");
}

h2 {
   font-family: "Open Sans";
   font-style: normal;
   font-weight: bold;
   font-size: 3vw;
   line-height: 3.5vw;
   color: theme-color("titleColor");

   margin-bottom: $titleSectionMargin;

   text-transform: uppercase;

   @media (max-width: 991px) {
      font-size: 9vw;
      line-height: 9.5vw;
   }
}

h3 {
   font-family: "Open Sans";
   font-style: normal;
   font-weight: bold;
   font-size: 3.5vw;
   line-height: 4vw;
   color: theme-color("titleColor");
   text-transform: uppercase;

   &.titleHero {
      color: theme-color("bgColor");

      font-size: 65px;
      line-height: 64px;
   }
   &.membersTitle {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 2vw;
      line-height: 2.5vw;

      text-transform: uppercase;
      @media (max-width: 991px) {
         font-size: 6vw;
         line-height: 6.5vw;
      }
   }
   @media (max-width: 991px) {
      font-size: 7.5vw;
      line-height: 8vw;
   }
}

h4 {
   font-family: "Open Sans";
   font-style: normal;
   font-weight: bold;
   font-size: 2.5vw;
   line-height: 2.8vw;

   color: theme-color("titleColor");
   @media (max-width: 991px) {
      font-size: 5vw;
      line-height: 5.5vw;
   }
}

p {
   font-family: "Open Sans";
   font-style: normal;
   font-weight: normal;
   font-size: 1.1vw;
   line-height: 1.6vw;

   color: theme-color("textColor");
   @media (max-width: 991px) {
      font-size: 3vw;
      line-height: 3.5vw;
   }
}

.featuredText {
   color: theme-color("secondary");
}
.boldText {
   font-weight: bold;
}

.textStroke {
   color: transparent !important;
   -webkit-text-stroke: 1px theme-color("titleColor");
}

.fullWidthBackgroundTextWrap {
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;

   & > div {
      width: 100%;
      display: flex;
      justify-content: center;

      &:nth-child(2) {
         p {
            &:nth-child(1) {
               margin-right: 0.2em;
            }
         }
      }
   }
   span {
      width: 100%;
   }

   .fullWidthBackgroundTextStroke {
      -webkit-text-stroke: 1px theme-color("textColor");
      color: transparent;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 9.3vw;
      line-height: 9.3vw;

      &.katName {
         font-family: "Open Sans";
         font-style: normal;
         font-weight: bold;
         line-height: 11vw;
      }
      @media (max-width: 1600px) {
         font-size: 14.3vw;
         line-height: 18.3vw;
         &.katName {
            font-size: 10.3vw;
            line-height: 10.3vw;
         }
         &.fullWidthBackgroundTextStrokeES {
            font-size: 10.5vw;
            line-height: 8.3vw;
         }
         &.katNameEs {
            font-size: 12.5vw;
            line-height: 10.5vw;
         }
      }
      @media (max-width: 991px) {
         display: none;
      }
   }
}

// NAVBAR
.linksNav {
   span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 0.9vw;
      line-height: 1.3vw;

      color: theme-color("bgColor");

      // @media (max-width: 1400px) and (min-width: 992px) {
      //   font-size: 1.1vw;
      //   line-height: 1.5vw;
      // }
      @media (max-width: 991px) {
         font-size: 3vw;
         line-height: 3.5vw;

         color: theme-color("titleColor");
      }
   }
}

// FOOTER
.footerTitle,
.footerText {
   font-size: 1vw;
   line-height: 1.2vw;

   @media (max-width: 991px) {
      font-size: 4vw;
      line-height: 4.5vw;
   }
}
.footerTitle {
   font-family: "Open Sans";
   font-style: normal;
   font-weight: bold;

   color: theme-color("secondary");
}

.footerText {
   font-family: "Open Sans";
   font-style: normal;
   font-weight: normal;
}

// BUTTONS

.buttonText {
   font-family: "Open Sans";
   font-style: normal;
   font-weight: bold;
   font-size: 0.8vw;
   line-height: 1.1vw;

   span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 0.8vw;
      line-height: 1.1vw;

      color: theme-color("bgColor");
   }
   @media (max-width: 991px) {
      font-size: 3vw;
      line-height: 3.5vw;
      span {
         font-size: 3vw;
         line-height: 3.5vw;
      }
   }
}
.buttonPaymentText {
   font-family: "Open Sans";
   font-style: normal;
   font-weight: bold;

   span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 1.3vw;
      line-height: 1.8vw;
      @media (max-width: 991px) {
         font-size: 4vw;
         line-height: 4.5vw;
      }
   }
}

// CARDS
.cardTitle {
   font-family: "Open Sans";
   font-style: normal;
   font-weight: bold;
   font-size: 1.6vw;
   line-height: 1.8vw;

   color: theme-color("titleColor");

   text-shadow: 0 2px 5px theme-color("textColor");

   @media (max-width: 1600px) {
      font-size: 2.2vw;
      line-height: 2.4vw;

      margin-left: auto;
      margin-right: auto;

      // width: max-content;
   }
   @media (max-width: 991px) {
      font-size: 5vw;
      line-height: 5.5vw;
   }
}

.numbersStroke {
   position: relative;
   font-family: "Microgramma D Extended";
   font-style: normal;
   font-weight: bold;
   font-size: 6.5vw;
   line-height: 7vw;
   //width: 100%;
   color: transparent;
   -webkit-text-stroke: 2px theme-color("neonSecondary");
   // text-shadow: inset 2px 2px 3px rgba(10, 10, 10, 0.5);
   z-index: 1;

   @for $i from 1 through 3 {
      &.numbersStroke#{$i} {
         &::before {
            content: "0#{$i}";
         }
      }
   }
   &::before {
      position: absolute;
      // top: 0;
      // left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      -webkit-text-stroke: 6px theme-color("neonSecondaryBlurr");
      filter: blur(2px);
   }

   @media (max-width: 991px) {
      width: 100%;
      font-size: 15vw;
      position: relative;
      line-height: 23.5vw;
      -webkit-text-stroke: 0.3vw theme-color("neonSecondary");
      &::before {
         -webkit-text-stroke: 2vw theme-color("neonSecondaryBlurr");
         filter: blur(1vw);
      }
   }
}

// CUSTOM LIST
.customList {
   list-style: none;
   position: relative;
   padding-left: calc(calc(#{$customListBulletSize} * 2));
   // width: 60%;
   li {
      @extend p;
      padding: 1em 0;
      border-bottom: 1px solid theme-color("bordersColor");

      display: flex;
      justify-content: flex-start;
      align-items: center;

      &::before {
         content: "";
         position: absolute;
         left: 0;

         // font-weight: bold;
         // display: inline-block;
         // vertical-align: middle;
         width: $customListBulletSize;
         height: $customListBulletSize;
         max-width: 15px;
         max-height: 15px;
         // margin-right: 1em;
         margin: 0;
         background: theme-color("secondary");
         transform: skewX(-7deg);
      }
      @media (max-width: 991px) {
         // position: relative;
         padding-left: 0;

         &::before {
            // position: absolute;
            // left: 0;
            // top: 50%;
            // transform: translateY(-50%);
            width: $customListBulletSizeMobile;
            height: $customListBulletSizeMobile;
         }
      }
   }
   &.withMarginLeft {
      // margin-left: 3vw;
   }
}

// PAYMENT
.priceText {
   font-family: "Open Sans";
   font-style: normal;
   font-weight: bold;
   font-size: 5vw;
   line-height: 5.2vw;
   color: theme-color("bgColor");

   &.beforePrice {
      position: relative;
      font-size: 3vw;
      line-height: 3.2vw;
      &:before {
         position: absolute;
         align-content: center;
         content: "";
         width: 100%;
         top: 50%;
         left: 0;
         border-top: 2px solid;
         border-color: inherit;
         -webkit-transform: rotate(-15deg);
         -moz-transform: rotate(-15deg);
         -ms-transform: rotate(-15deg);
         -o-transform: rotate(-15deg);
         transform: rotate(15deg);
         color: theme-color("secondary");
         opacity: 1;
      }
   }
   @media (max-width: 991px) {
      font-size: 15vw;
      line-height: 15.5vw;
   }
}
