/*******************************
*
* Customize your CSS here
*
********************************/

body {
  background-color: theme-color('bgColor');
}
#root {
  background-color: theme-color('primary');
}
*,
*::after,
*::before {
  box-sizing: border-box;
}

.layoutContainer {
  @media (max-width: 991px) {
    overflow: hidden;
  }
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.triangleTopLeft {
  position: relative;

  &::before {
    content: url('../../imgs/icons/triangleTopLeft.svg');
    position: absolute;
    top: 0;
    left: 0;
    width: 5vw;
    max-width: 160px;
    margin-left: -1px;
    margin-top: -1px;

    @media (max-width: 991px) {
      width: 10vw;
    }
  }
  &.triangleTopLeftBlurrPrimary {
    &::before {
      content: url('../../imgs/icons/triangleTopLeftBlurrPrimary.svg');
    }
  }
  &.triangleTopLeftBlurrSecondary {
    &::before {
      content: url('../../imgs/icons/triangleTopLeftBlurrSecondary.svg');
    }
  }
  &.triangleTopLeftBlurr {
    &::before {
      width: 6vw;
      margin-left: -12px;
      margin-top: -12px;
      z-index: 3;
    }
  }
}

.triangleTopLeftPay {
}

.cardStylePayV {
  padding: 4vw 3vw;
  background: url(../../imgs/icons/sqtrianglev.png) no-repeat center center !important;
  border: 0 !important;
  background-size: 100% 100% !important;
}

.cardStylePayY {
  padding: 4vw 3vw;
  background: url(../../imgs/icons/sqtriangley.png) no-repeat center center !important;
  border: 0 !important;
  background-size: 100% 100% !important;
}

.triangleTopRight {
  position: relative;

  &::before {
    content: url('../../imgs/icons/triangleTopLeft.svg');
    transform: scaleX(-1);
    position: absolute;
    top: 0;
    right: 0;
    width: 5vw;
    max-width: 160px;
    margin-right: -1px;
    margin-top: -1px;
    z-index: 1;
    @media (max-width: 991px) {
      width: 10vw;
    }
  }
}
.triangleBottomLeft {
  position: relative;

  &::before {
    content: url('../../imgs/icons/triangleTopLeft.svg');
    transform: scaleY(-1);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 5vw;
    max-width: 160px;
    margin-left: -1px;
    margin-bottom: -1px;
    z-index: 1;
    @media (max-width: 991px) {
      width: 10vw;
    }
  }
}
.triangleBottomRight {
  position: relative;

  &::before {
    content: url('../../imgs/icons/triangleTopLeft.svg');
    transform: scale(-1);
    position: absolute;
    bottom: 0;
    right: 0;
    width: 5vw;
    max-width: 160px;
    margin-right: -1px;
    margin-bottom: -1px;
    z-index: 1;
    @media (max-width: 991px) {
      width: 10vw;
    }
  }
}

.triangleBgColor2 {
  &::before {
    content: url('../../imgs/icons/triangleTopLeft2.svg') !important;
  }
}
// // SCROLLBAR
// .c-scrollbar {
//   z-index: 99999999;
// }
// //

.paddingTopSticky {
  padding-top: 72px;
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}

.gs_reveal_title {
  // overflow: hidden;
  // opacity: 0;
  // visibility: hidden;
  margin-bottom: 0;
}

.moby {display: inline;}
.mobyb {display: block;}
.mobn, .mobnb {display: none;}

@media (min-width: 720px) {
	.moby, .mobyb {display: none}
	.mobn {display: inline-block}
	.mobnb {display: block}
}
