.rowSection {
  .tipsCol {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      margin-bottom: 0;
    }
    h3 {
      margin-bottom: $titleSectionMargin - 0.5em;
      @media (max-width: 991px) {
        margin-bottom: $titleSectionMargin;
      }
    }

    &.tipsColImg {
      justify-content: flex-start;
      .tipsImg {
        width: 80%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 991px) {
          width: 100%;
        }
      }
      @media (max-width: 991px) {
        justify-content: center;
        margin-bottom: calc(#{$titleSectionMargin} * 2);
      }
    }
    &.tipsColTitle {
      @media (max-width: 991px) {
        text-align: center;
      }
    }
  }
}
