.RootThankPage {
  height: 100vh;
  .sectionPolygon1,
  .sectionPolygon2 {
    background-color: theme-color('bgColor2');
  }

  .rowSection {
    display: flex;
    justify-content: center;
    align-items: center;
    .thankCol {
      .vslInsideImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (max-width: 991px) {
          min-height: calc(100vw - 30vw);
        }
      }
      .textBackgroundWrap {
        h2 {
          margin-bottom: 0 !important;
        }
      }
      h4 {
        margin: 3vw 0;
        color: theme-color('primary');

        @media (max-width: 991px) {
          margin: 10vw 0;
        }
      }
      .customList {
        li {
          border: none;
          @media (max-width: 991px) {
            margin-bottom: 0;
          }
        }
      }
      &.thankColTitle {
        padding-right: 6vw;
        & > div {
          @media (max-width: 991px) {
            text-align: center;

            .buttonStyle {
              margin: 5vw 0 10vw 0;
              width: 100%;
            }
          }
        }
      }
      &.thankColVid {
        padding: 0;
        .playerWrap {
          width: calc(100% + 10vw);
          @media (max-width: 991px) {
            width: 100%;
          }
        }
        .triangleTopLeft {
          &::before {
            max-width: 100px;
            width: 5vw;

            @media (max-width: 991px) {
              width: 10vw;
            }
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    height: auto;
    padding-top: 20vw;
    padding-bottom: $sectionsPaddingMobile;
  }
  @media (max-width: 500px) {
    padding-top: 100px;
  }
}
.RootThankFaq {
  background: theme-color('bgColor2');

  .rowSection {
    display: flex;
    justify-content: center;
    align-items: center;
    .thankFaqCol {
      text-align: center;
    }
  }
}

.navbarThank {
  display: flex;
  justify-content: center;
  align-items: center;
  .navbarLogo {
    mix-blend-mode: normal !important;
  }
  svg {
    path {
      fill: theme-color('titleColor');
    }
  }
}
