.promo25Off {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 8vw;
  line-height: 7vw;

  background: -webkit-linear-gradient(left, #f6af25, #fff0d3, #f6af25);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.RootUpdate2_0 {
  // padding-bottom: 0 !important;
  .rowSection {
    max-width: 100%;

    .update2_0ColButton {
      display: flex;
      justify-content: center;
      align-items: center;

      z-index: 2;
    }
  }
  .updateImg {
    width: 100%;

    @media (max-width: 991px) {
      object-fit: cover;
      height: 100%;
    }
  }

  .update2_0ColImgs {
    position: relative;
    .percentOff {
      width: 25vw;
      position: absolute;
      bottom: 0;
      right: 10vw;
      z-index: 1;

      animation: heartbeat 4s infinite;

      @media (max-width: 991px) {
        width: 50vw;
        right: 0;
      }
    }
  }

  @media (max-width: 991px) {
    .update2_0Col {
      padding: 0;
    }

    .update2_0ColButton {
      margin-top: 6vw;
    }
  }
}
