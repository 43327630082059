.rowSection {
   .homeCol {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;

      .buttonGroupWrap {
         width: 100%;
         .buttonStyle {
            &.button--calypso-primary {
               outline-color: theme-color("bgColor");
               &:hover {
                  outline-color: theme-color("primary");
               }
            }
            &:nth-child(1) {
               margin-right: 1vw;
            }
            &:nth-child(2) {
               margin-left: 1vw;
            }
            svg {
               margin-right: 1vw;
            }
            @media (max-width: 991px) {
               svg {
                  margin-right: 2vw;
               }
               &:nth-child(1) {
                  margin-right: 3vw;
               }
               &:nth-child(2) {
                  margin-left: 3vw;
               }
               span {
                  font-size: 2.3vw;
                  line-height: 2.8vw;
               }
            }
         }
      }

      .homeTitleDivWrap {
         img {
            width: 100%;
            @media (max-width: 991px) {
               width: 100%;
            }
         }
      }
      &.homeColTitle {
         @media (max-width: 991px) {
            padding-bottom: 15vw;
         }
      }

      @media (max-width: 991px) {
         height: auto;
      }
   }
   .downArrowWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 5vw;

      .downArrow {
         cursor: pointer;
         @media (max-width: 991px) {
            width: 15vw;
         }
      }
   }
}

.homeTextGolden {
   background: -webkit-linear-gradient(#f6af25, #fff9e3);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   color: tomato;
}
