a,
button {
  outline: none;
  box-shadow: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.buttonStyle {
  // pointer-events: auto;
  cursor: pointer;
  border: none;
  padding: 1.5vw 3vw;
  margin: 0;
  // font-family: inherit;
  // font-size: inherit;
  position: relative;
  display: inline-block;

  transform: skewX(-20deg);
  transition: outline-color 0.3s, box-shadow 0.3s;

  span,
  svg {
    transform: skewX(20deg);
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.button--calypso {
    overflow: hidden;
    // font-size: 1.15rem;

    svg {
      z-index: 10;

      path {
        transition: color 0.3s;
        color: theme-color('bgColor');
      }
    }
    span {
      color: theme-color('bgColor');
      display: block;
      position: relative;
      z-index: 10;

      text-transform: uppercase;

      transition: color 0.3s;
    }
    &:hover {
      span,
      svg {
        animation: MoveScaleUpInitial 0.3s forwards,
          MoveScaleUpEnd 0.3s forwards 0.3s;
      }
      &::before {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
      }
      &::after {
        transform: translate3d(0, 0, 0);
        transition-duration: 0.05s;
        transition-delay: 0.4s;
        transition-timing-function: linear;
      }
    }
    &::before {
      content: '';
      background: theme-color('bgColor');
      width: 120%;
      height: 0;
      padding-bottom: 120%;
      top: -110%;
      left: -10%;
      border-radius: 50%;
      transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
    }
    &::after {
      content: '';
      background: theme-color('bgColor');
      transform: translate3d(0, -110%, 0);
      transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    }
  }

  &.button--calypso-primary {
    background: theme-color('primary');
    outline: 2px solid theme-color('neonSecondary');

    box-shadow: 0 0 10px theme-color('secondary');

    &:hover {
      outline-color: theme-color('primary');
      box-shadow: 0 0 10px theme-color('primary');
      span {
        color: theme-color('primary');
      }
    }
  }
  &.button--calypso-secondary {
    background: transparent;
    border: 2px solid theme-color('secondary');

    display: inline-flex;

    &:hover {
      span {
        color: theme-color('secondary');
      }
      svg {
        path {
          color: theme-color('secondary');
        }
      }
    }
  }
  &.button--calypso-secondary-payment {
    background: theme-color('primary');
    outline: 2px solid theme-color('neonPrimary');
    box-shadow: 0 0 10px theme-color('primary');

    // display: inline-flex;

    &:hover {
      outline-color: theme-color('neonSecondary');
      box-shadow: 0 0 10px theme-color('secondary');
      span {
        color: theme-color('secondary');
      }
      svg {
        path {
          color: theme-color('secondary');
        }
      }
    }
  }

  @media (max-width: 991px) {
    padding: 4vw 2vw;
  }
}

.buttonGroupWrap {
  display: flex;
  .buttonStyle {
    // height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0px;
  }
  @media (max-width: 991px) {
    // flex-wrap: wrap;
    .buttonStyle {
      // width: 100%;
      // flex: auto;
    }
  }
}
// }

@keyframes MoveScaleUpInitial {
  to {
    transform: translate3d(0, -105%, 0) scale3d(1, 2, 1) skewX(20deg);
    opacity: 0;
  }
}

@keyframes MoveScaleUpEnd {
  from {
    transform: translate3d(0, 100%, 0) scale3d(1, 2, 1) skewX(20deg);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0) skewX(20deg);
    opacity: 1;
  }
}
