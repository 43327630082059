.longLanding {
  .membersColGinna,
  .membersColSonia {
    padding: 1.5vw !important;
    @media (max-width: 991px) {
      padding: 0 !important;
    }
  }
  .contentWrap {
    display: flex;
    justify-content: center;
    align-items: center !important;
  }
  .membersCol .contentWrap {
    @media (max-width: 991px) {
      width: 100% !important;
    }
  }
  .membersColSonia {
    @media (max-width: 991px) {
      margin-top: 10vw;
    }
  }

  // .tipsCol {
  //   h2 {
  //     // margin-bottom: $titleSectionMargin;
  //   }
  // }
  &.membersRow {
    &:nth-child(2) {
      align-items: flex-end;
    }
    padding-right: 10vw;
    .membersColQuotes {
      .contentWrap {
        width: 100% !important;
        padding: 0 0 0 5vw;
      }
    }
    .membersColSonia {
      padding-right: 0 !important;
      padding-left: 3vw !important;
    }
    @media (max-width: 991px) {
      padding-right: 0;
      .membersColSonia {
        padding-left: 0 !important;
      }
    }
  }
}
