// ACCORDION
.rowSection {
  .faqColTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $titleSectionMargin;
    h2 {
      margin-bottom: 0;
    }
    img {
      height: 250%;
      @media (max-width: 991px) {
        // height: auto;
        // width: 120%;
        height: 150%;
      }
    }
    @media (max-width: 991px) {
      justify-content: center;
      text-align: center;
    }
  }
  .faqCol {
    &:nth-child(1) {
      padding-right: 3vw;
    }
    &:nth-child(2) {
      padding-left: 3vw;
    }
  }
  .faqColBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .accordionFaq {
    width: 100%;
    .faqCol {
      .accordionCardSpace {
        background-color: transparent;

        margin: 3em 0;
        @media (max-width: 991px) {
          margin: 0;
        }
      }
      &:nth-last-child(1) {
        .accordionCardSpace {
          &:nth-last-child(1) {
            border-bottom: none !important;
          }
          @media (max-width: 991px) {
            &:nth-last-child(1) {
              margin-top: 3em;
            }
          }
        }
      }
    }
    @media (max-width: 991px) {
      margin-top: 5vw;
    }
  }
  .accordionCardTitle {
    background: transparent !important;
    &.accordionCardTitle2 {
      margin-top: 3em;
    }
    * {
      background: transparent !important;
    }
  }
  .accordionCard {
    position: relative;
    border: none;
    border-bottom: 1px solid theme-color('secondary') !important;
    border-radius: 0;

    margin-left: 6vw !important;

    &.accordionCardFaq {
      .accordionToggle {
        background: transparent;
        border: none;
        padding: 3em 2em 2vw 0;
        cursor: pointer;
        .accordionToggleDiv {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .accordionToggleIcon {
            position: absolute;
            left: -6vw;

            // padding: 2vw;
            background-color: theme-color('secondary');
            border: 1px solid theme-color('secondary');
            border-radius: 0;
            max-width: 80px;
            max-height: 80px;
            width: 5vw;
            height: 5vw;

            transform: skewX(-10deg);

            display: flex;
            justify-content: center;
            align-items: center;

            // svg,
            // path {
            //   background: transparent !important;
            //   fill: theme-color('secondary');
            // }
            img {
              width: 70%;
              transform: skewX(10deg);
            }

            @media (max-width: 991px) {
              width: 10vw;
              height: 10vw;

              left: 0;
              position: relative;
              flex: none;

              margin-right: 5vw;

              img {
                width: 4vw;
              }
            }
          }
          h4 {
            margin-bottom: 0;
          }
        }
      }
    }
    .accordionBody {
      padding: 0 2em 2em 0;
      .accordionText {
        span {
          color: theme-color('primary');
        }
        a {
          color: theme-color('secondary');
        }

        p {
          opacity: 0.8;
        }
      }
      @media (max-width: 991px) {
        padding: 0 1vw 1vw 0;
      }
    }

    @media (max-width: 991px) {
      margin-left: 0 !important;
    }
  }
}
