/***********************************************************
* 
* This file imports all the necessary SASS & CSS stylesheets
* Please, do not edit this file
*
***********************************************************/

// Bootstrap Importing
@import '../node_modules/bootstrap/scss/bootstrap';

// MAIN SCSS
@import './assets/sass/Main.scss';

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;700&display=swap');

// MAINTENANCE
@import './Maintenance.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
