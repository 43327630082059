@font-face {
  font-family: 'Open Sans';

  src: url('./OpenSans-Regular.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';

  src: url('./OpenSans-Bold.ttf') format('truetype');

  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';

  src: url('./OpenSans-BoldItalic.ttf') format('truetype');

  font-weight: bold;
  font-style: italic;
}
