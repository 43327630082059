.RootContainers {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: theme-color('bgColor');
}
.rowSection {
  width: 100%;
  height: 100%;
  max-width: 80%;

  @media (max-width: 1600px) {
    max-width: 90%;
  }
}

.sectionsPadding {
  padding: $sectionsPadding 0;
  @media (max-width: 991px) {
    padding: $sectionsPaddingMobile 0;
  }
}

.textBackgroundWrap {
  position: relative;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  z-index: 1;
  .textBackground {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: -1;
    height: 150%;
  }
}

.sectionPolygonBottomExtern {
  .sectionPolygon1,
  .sectionPolygon2 {
    position: absolute;
    content: '';
    bottom: -1px;
    width: 50%;
    height: 10vw;
    background-color: theme-color('bgColor');
    z-index: 2;
  }
  .sectionPolygon1 {
    left: 0;
    clip-path: polygon(0 50%, 0% 100%, 100% 100%);
  }
  .sectionPolygon2 {
    left: 50%;
    clip-path: polygon(100% 50%, 0% 100%, 100% 100%);
  }
}
.sectionPolygonBottomCenter {
  .sectionPolygon1,
  .sectionPolygon2 {
    position: absolute;
    content: '';
    bottom: -1px;
    width: 50%;
    height: 10vw;
    background-color: theme-color('bgColor');
    z-index: 2;
  }
  .sectionPolygon1 {
    left: 50%;
    clip-path: polygon(0 50%, 0% 100%, 100% 100%);
  }
  .sectionPolygon2 {
    left: 0%;
    clip-path: polygon(100% 50%, 0% 100%, 100% 100%);
  }
}
.sectionPolygonTopExterior {
  .sectionPolygon1,
  .sectionPolygon2 {
    position: absolute;
    content: '';
    top: -1px;
    width: 50%;
    height: 5vw;
    background-color: theme-color('bgColor');
    z-index: 2;
  }
  .sectionPolygon1 {
    left: 50%;
    clip-path: polygon(0% 0%, 100% 100%, 100% 0%);
  }
  .sectionPolygon2 {
    left: 0%;
    clip-path: polygon(100% 0%, 0% 0%, 0% 100%);
  }
}

@media (max-width: 991px) {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.playerWrap {
  position: relative;
  z-index: 1;
  .embedContainer {
    z-index: -1;

    .play {
      display: none;
    }
  }
}

// PLAY
.playContainer {
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  top: 0;
  left: 0;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity 0.3s;

  // top: 0;
  // left: 0;
  svg {
    transition: opacity 0.3s;

    width: 8vw;
    height: auto;
    @media (max-width: 991px) {
      width: 15vw;
    }
  }

  &:hover {
    svg {
      // opacity: 0.6;

      // path {
      //   fill: theme-color('bgColor');
      //   stroke: theme-color('secondary');
      //   stroke-width: 2px;
      // }
    }
  }
  &.playingVideo {
    opacity: 0;
    // display: none;
  }
  .js-magnetic-content {
    margin-top: 40px;
  }
}

.cropBorderLeft {
  // https://css-tricks.com/almanac/properties/c/clip-path/
  clip-path: polygon(
    20% 0%,
    100% 0%,
    100% 20%,
    100% 80%,
    100% 100%,
    0% 100%,
    0% 80%,
    0% 20%
  );
}

.cropBorderRight {
  // https://css-tricks.com/almanac/properties/c/clip-path/
  clip-path: polygon(
    0% 0%,
    80% 0%,
    100% 20%,
    100% 80%,
    100% 100%,
    0% 100%,
    0% 80%,
    0% 20%
  );
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  5% {
    transform: scale(1.25);
  }

  10% {
    transform: scale(1);
  }

  15% {
    transform: scale(1.25);
  }

  20% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}
