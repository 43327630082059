//  CARDS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

$grid-gutter-width: 30px !default;
$grid-gutter-width2: 60px !default;

$cards-per-line: (
  xs: 1,
  sm: 1,
  md: 1,
  lg: 2,
  xl: 2,
);
$cards-per-line-2: (
  xs: 1,
  sm: 1,
  md: 1,
  lg: 3,
  xl: 3,
);

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .card-deck .card.cardConfig {
      flex: 0 0
        calc(#{100 / map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
    }
    .card-deck .card.cardConfig2 {
      flex: 0 0
        calc(
          #{100 / map-get($cards-per-line-2, $name)}% - #{$grid-gutter-width}
        );
    }
  }
}

.cardStyle {
  background-color: theme-color('bgColor');
  border: 1px solid theme-color('bordersColor');
  border-radius: 0;

  .card-header {
    background-color: transparent;
    border-bottom: none;
  }
  .card-footer {
    background-color: transparent;
    border-top: none;
  }

  &.cardStyleSingle {
    padding: 4vw 3vw;

    background-color: theme-color('primary');
  }
  &.cardStyleSecondary {
    padding: 4vw 3vw;

//    background-color: theme-color('secondary') !important;
  }
  &.cardBG {
    border: 0 !important;
  }
}

.cardBGi {
  z-index: 2;
  position: absolute;
  width: 70vw;
  height: 100%;
  background: url(../../imgs/icons/sqtrianglevi.png) no-repeat 0px 0px;
  background-size: 100% 100%;
}
@media (min-width: 576px) {
  .cardBGi {
    width: 73vw;
  }
}
@media (min-width: 992px) {
  .cardBGi {
    width: 27vw;
  }
}
.cardBGy {
  background-image: url(../../imgs/icons/sqtriangleyi.png);
}

.neonBorder {
  position: relative;
  border: none !important;
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &::before {
    filter: blur(4px);
  }
  &.neonBorderPrimary {
    &::before {
      border: 4px solid theme-color('neonPrimaryBlurr');
      outline: 4px solid theme-color('neonPrimaryBlurr');
      z-index: 1;
    }
    &::after {
      border: 2px solid theme-color('neonPrimary');
      outline: 2px solid theme-color('neonPrimary');
      // outline-offset: 1px;
      z-index: 2;
    }
  }
  &.neonBorderSecondary {
    &::before {
      border: 4px solid theme-color('neonSecondaryBlurr');
      outline: 4px solid theme-color('neonSecondaryBlurr');
      z-index: 1;
    }
    &::after {
      border: 2px solid theme-color('neonSecondary');
      outline: 2px solid theme-color('neonSecondary');
      z-index: 2;
    }
  }
}
