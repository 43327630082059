.rowSection {
  .paymentRow {
    width: 100%;
    .paymentCol {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 991px) {
        &:nth-child(1) {
          padding: 0;
        }
        h2 {
          text-align: center;
        }
      }
    }
    &.paymentRowPrice {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .paymentCol {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        .buttonStyle {
          width: 100%;
        }
        .button--calypso-primary {
          background-color: theme-color('secondary');
          border-color: theme-color('secondary');
          &:hover {
            // span {
            //   color: theme-color('titleColor');
            // }
          }
        }
        .button--calypso-secondary {
          &::after,
          &::before {
            background-color: theme-color('secondary');
          }
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            span {
              color: theme-color('bgColor') !important;
            }
          }
        }
        .cardStyleSingle {
          text-align: center;
          background: theme-color('primaryGradient');
          .cardBody,
          .cardHeader,
          .card-footer {
            z-index: 4 !important;
          }
          .cardHeader {
            position: unset;
            &::before {
              @extend .triangleTopLeftBlurr;

              z-index: 3;
            }
          }
          .cardTitle {
            color: theme-color('secondary');
          }
          .cardText {
            color: theme-color('bgColor');
          }
          .cardBody {
            padding: 2vw 0 3vw 0;
            justify-content: center;
            display: flex;
            align-items: center;

            & > div {
              display: flex;
              justify-content: center;
              align-items: center;

              flex-wrap: wrap;
              & > div {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .priceText {
              background: theme-color('secondaryGradient');
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              &.beforePrice {
                background: transparent;
                color: theme-color('bgColor');
                -webkit-background-clip: unset;
                -webkit-text-fill-color: unset;
              }
            }
            @media (max-width: 991px) {
              padding: 1vw 0 6vw 0;
            }
          }

          &:nth-child(1) {
//            @extend .neonBorder;
//            @extend .neonBorderPrimary;
            .cardTitle {
              width: max-content;
            }
            .cardHeader {
              &::before {
//                content: url('../../../imgs/icons/triangleTopLeftBlurrPrimary.svg');
              }
            }
          }
          &:nth-last-child(1) {
            background: theme-color('secondaryGradient');
            border: none;
            position: relative;
            .cardTitle {
              color: theme-color('primary');
            }
            .priceText {
              background: theme-color('primaryGradient');
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &.beforePrice {
                &:before {
                  color: theme-color('primary');
                }
              }
            }

//            @extend .neonBorder;
//            @extend .neonBorderSecondary;

            .cardHeader {
              &::before {
//                content: url('../../../imgs/icons/triangleTopLeftBlurrSecondary.svg');
              }
            }

            .cardText {
              color: theme-color('bgColor');
            }
            .priceText {
              color: theme-color('bgColor');
            }
            .buttonStyle {
              span {
                // color: theme-color('bgColor');
              }
            }
          }
        }

        .card-deck {
          @media (max-width: 991px) {
            width: 100%;

            .cardStyleSingle {
              padding: 12vw 8vw;
              text-align: center;
              &:nth-child(1) {
                margin-bottom: 10vw;
              }
            }
          }
        }
        @media (max-width: 991px) {
          .cardStyleSingle {
            width: 100%;
            padding: 12vw 8vw;
            text-align: center;
            &:nth-child(1) {
              margin-bottom: 10vw;
            }
          }
        }
      }
    }
  }
}
