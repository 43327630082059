.RootLegals {
  background-color: theme-color('primary');
  padding-top: 100px;
  max-width: 90%;
  margin: 0 auto;
  p,
  li,
  span {
    color: theme-color('bgColor');
    opacity: 0.8;
  }
  a {
    color: theme-color('secondary');
  }
  h2,
  h4 {
    color: theme-color('bgColor');
  }

  h4 {
    margin: 3vw 0;
  }
}
