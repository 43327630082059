.RootFooter {
  .sectionPolygon1,
  .sectionPolygon2 {
    background-color: theme-color('bgColor2');
  }
  // background-color: theme-color("primary");
  height: auto;
  // padding: 250px 50px 50px 50px;
  background-color: theme-color('primary');
  display: flex;
  justify-content: center;
  position: relative;
  padding: $sectionsPadding 0 3vw 0;
  // padding-top: 15vw;
  // padding-bottom: 3vw;
  .rowSection {
    @media (max-width: 991px) {
      max-width: 80%;
    }
  }
  @media (max-width: 991px) {
    margin-left: 0 !important;
  }

  .footerCol {
    display: flex;
    justify-content: center;
    align-items: center;
    .footerBrand {
      width: 100%;
    }
    .listGroupFooter {
      width: fit-content;
      @media (max-width: 991px) {
        width: 100%;
      }
    }

    .footerColWrapper {
      // width: 100%;
      .footerColDiv {
        display: flex;
        align-items: center;
        // justify-content: center;

        &:nth-child(1) {
          padding-left: 3em;
        }
        @media (max-width: 991px) {
          justify-content: center;
          &:nth-child(1) {
            padding-left: 0;
          }
        }
        // &:nth-child(2) {
        //   justify-content: flex-start;
        // }

        .footerSubTitle .textStroke {
          -webkit-text-stroke: 1px theme-color('primary');
          // color: theme-color('primary');
        }
      }
    }
    .footerFaceText {
      margin-top: 3vw;
      color: theme-color('bgColor');
      font-size: 0.7vw;
      @media (max-width: 1500px) and (min-width: 992px) {
        font-size: 0.9vw;
      }
      @media (max-width: 991px) {
        margin-top: 15vw;

        font-size: 3vw;
      }
    }
    @media (max-width: 991px) {
      text-align: left;
    }
  }
}

// BUTTONS
.socialButtonsGroup {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  font-family: 'Bruno Ace';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 75px;

  text-align: center;
  text-transform: uppercase;
}

.copyTextGroup {
  margin: 0 auto;
  text-align: center;
}

.listGroupItemFooter a {
  text-decoration: none;
  color: theme-color('bgColor');
  transition: 0.3s;
}

.copyText {
  color: theme-color('secondary');
  display: block;
}

.logoFooter {
  font-size: 74px;
  line-height: 80px;
  margin-bottom: 20px;
}

.listGroupItemFooter {
  background-color: transparent;
  border: none;
  padding: 0;
  padding-bottom: 0.3em;
  margin-bottom: 0.3em;

  transition: opacity 0.3s;
  &:hover {
    opacity: 0.5;

    a {
      color: theme-color('bgColor');
    }
  }
}

.listGroupTitle {
  color: theme-color('buttonsColor');

  padding: 0;
  //  padding-bottom: 1em;

  display: flex;
  align-items: center;
  text-transform: uppercase;
  @media (max-width: 991px) {
    // justify-content: center;
  }
}

/* UNDERLINE EFFECT */
.justify-content-center {
  margin: 0 auto;
}

.listGroupText a {
  position: relative;
  transition: 0.5s;
  // width: 100%;
}

.listGroupText a::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  top: 100%;
  left: 0;
  background: theme-color('primary');
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: right;
}

.listGroupText a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.listGroupText a:focus::after {
  transform: scaleX(1);
}

.poweredSection {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    margin-top: 0;
  }
}

.powered {
  // font-family: 'Roboto';
  // font-style: normal;
  // font-weight: 300;
  // font-size: 16px;
  // /* or 24px */

  // display: block;
  // text-align: center;
  color: theme-color('bgColor');

  a {
    color: theme-color('secondary');
  }
}

.powered a:hover {
  text-decoration: none;
}

.listGroupItemFooter a {
  cursor: pointer;
}

@media (max-width: 991px) {
  .RootFooter {
    padding-top: 20vw;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .socialButtons button {
    width: 50px;
    height: 50px;
  }

  .socialButtonsToolbar .btn {
    padding: 0;
  }

  .copyText:nth-child(1) {
    margin-bottom: 10px;
  }

  .brandFooter {
    margin-bottom: 50px;
  }

  .interestLinks {
    margin-bottom: 30px;
  }
}
