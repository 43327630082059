.section-parallax {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  //   display: flex;
  // padding: 1vw 0;

  img {
    margin: -1.5vw 0;
    transform: translateY(-1.5vw);
    will-change: transform;
    transition: transform 1s cubic-bezier(0, 0, 0, 1) 0s;
  }
}
